var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "규제 DB 업로드 목록",
            tableId: "impr01",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "btn1" &&
                  (props.row["chmUploadStateCd"] === "CUS0000015" ||
                    props.row["chmUploadStateCd"] === "CUS0000025")
                    ? [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "적용",
                                    icon: "inventory",
                                    color: "blue-custom",
                                  },
                                  on: {
                                    btnClicked: function ($event) {
                                      return _vm.applyChem(props.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : col.name === "btn2" &&
                      props.row["chmUploadStateCd"] !== "CUS0000020" &&
                      props.row["chmUploadStateCd"] !== "CUS0000001"
                    ? [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "삭제", icon: "delete" },
                                  on: {
                                    btnClicked: function ($event) {
                                      return _vm.deleteUpload(props.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : col.name === "chmUploadStateName"
                    ? [
                        props.row["chmUploadStateCd"] === "CUS0000015" ||
                        props.row["chmUploadStateCd"] === "CUS0000020"
                          ? _c("q-btn", {
                              staticClass: "tableinnerBtn",
                              attrs: {
                                flat: "",
                                align: "center",
                                color: "blue-6",
                                label: props.row[col.name],
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.linkClick(props.row)
                                },
                              },
                            })
                          : [_vm._v(" " + _vm._s(props.row[col.name]) + " ")],
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "등록", icon: "add" },
                        on: { btnClicked: _vm.openPop },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }